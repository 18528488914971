import React from "react"
import "../../components/my_global.scss"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Container, Row, Col } from "reactstrap"
import stick from "../../images/stick.webp"
import erobotphase from "../../images/EROBOT-phase.jpg"
import "../../components/prizes.css"
// images
import { StaticImage } from "gatsby-plugin-image"

const Solar1 = () => {
  return (
    <Layout>
      <SEO title={"What We're Reading"} />
      <div className="page-heading reading-bg">
        <Container>
          <Row>
            <Col lg="8" className="mx-auto text-center text-white">
              <h3 className="h3s"> American-Made Challenges</h3>
              <h2 className="h2s">Solar Prize Round 1</h2>
              <p
                className="page-hero-subheading"
                style={{ textAlign: "justify" }}
              >
                The American-Made Solar Prize is a $3 million prize competition
                designed to energize U.S. solar manufacturing through a series
                of contests and the development of a diverse and powerful
                support network that leverages national laboratories, energy
                incubators, and other resources across the country.{" "}
              </p>
              <div className="column is-12">
                <div className="has-text-centered">
                  <a href="https://www.herox.com/SolarPrize">
                    <button type="button" className="btns">
                      Follow the Challenge?
                    </button>
                  </a>
                </div>
                <span className="whitespace"></span>
              </div>
            </Col>
          </Row>
          <StaticImage src="../../images/stick.webp" className="stick" alt="stick" loading="eager" />
        </Container>
      </div>
      <div className="section_padding">
        <div className="container">
          <h1 className="mac_h1">Round 1 Go! Contest Winners</h1>
          <hr />
          <p className="pcol-fz">
            Congratulations to our two winners from the Go! Contest! These teams
            will be awarded both cash prizes and program vouchers for use at
            national laboratories and connector facilities.{" "}
          </p>
          <div>
            <table class="table table-hover">
              <thead>
                <tr>
                  <th>
                    entry title{" "}
                    <span class="is-size-7 has-text-weight-normal">
                      (listed alphabetically)
                    </span>
                  </th>
                  <th>team location</th>
                  <th className="tds">subject area</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/SolarPrize/round/378/entry/23374"
                      class="has-text-default"
                    >
                      Breakthrough PV cell &amp; module architecture (Solar
                      Inventions)
                    </a>
                  </td>
                  <td>Atlanta, GA</td>
                  <td className="tds">Cell Concepts</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/SolarPrize/round/378/entry/23380"
                      class="has-text-default"
                    >
                      Factory Installed Solar for Manufactured Homes (Phase3
                      Photovoltaics)
                    </a>
                  </td>
                  <td>Portland, OR</td>
                  <td className="tds">Module and Application Hardware</td>
                </tr>
              </tbody>
            </table>
            <div class="has-text-centered">
              <a
                href="https://www.herox.com/SolarPrize/community"
                target="_blank"
              >
                <button type="button" className="btns">
                  View the full Winner Submission on HeroX
                </button>
              </a>
            </div>
          </div>
        </div>
        <span style={{ padding: "5px" }}></span>
        <div className="container">
          <h1 className="mac_h1">Round 1 Set! Contest Finalists</h1>
          <hr />
          <p className="pcol-fz">
            Congratulations to all of the finalists from the Set! Contest! These
            teams will be awarded both cash prizes and program vouchers for use
            at national laboratories and connector facilities. Round 1 Finalists
            and Semifinalists are eligible to compete in the Go! Contest.{" "}
          </p>
          <div>
            <table class="table is-hoverable is-fullwidth set-table">
              <thead>
                <tr>
                  <th>
                    entry title{" "}
                    <span class="is-size-7 has-text-weight-normal">
                      (listed alphabetically)
                    </span>
                  </th>
                  <th>team location</th>
                  <th className="tds">subject area</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/SolarPrize/round/354/entry/22842"
                      class="has-text-light"
                    >
                      Acoustic Cleaving of Gallium Arsenide for Next Generation
                      PV (Crystal Sonic)
                    </a>
                  </td>
                  <td>Tempe, AZ</td>
                  <td className="tds">Cell Concepts</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/SolarPrize/round/354/entry/22856"
                      class="has-text-light"
                    >
                      Factory Installed Solar for Manufactured Homes (Phase3
                      Photovoltaics)
                    </a>
                  </td>
                  <td>Portland, OR</td>
                  <td className="tds">Module and Application Hardware</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/SolarPrize/round/354/entry/22820"
                      class="has-text-light"
                    >
                      First Step Solar (Catalyst)
                    </a>
                  </td>
                  <td>Dearborn, MI</td>
                  <td className="tds">Module and Application Hardware</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/SolarPrize/round/354/entry/22776"
                      class="has-text-light"
                    >
                      High-Capacity 250 Kilowatt Silicon Carbide Solar String
                      Inverter (BREK Electronics Corp.)
                    </a>
                  </td>
                  <td>Boulder, CO</td>
                  <td className="tds">Devices</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/SolarPrize/round/354/entry/22849"
                      class="has-text-light"
                    >
                      Omnisole (Omnisole)
                    </a>
                  </td>
                  <td>Worcester, MA</td>
                  <td className="tds">Module and Application Hardware</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/SolarPrize/round/354/entry/22838"
                      class="has-text-light"
                    >
                      Perovskite + Silicon Tandem Product Prototype (Tandem PV)
                    </a>
                  </td>
                  <td>Morgan Hill, CA</td>
                  <td className="tds">Cell Concepts</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/SolarPrize/round/354/entry/22851"
                      class="has-text-light"
                    >
                      Scalable Energy Empowerment Device (Solar SEED)
                    </a>
                  </td>
                  <td>New York, NY</td>
                  <td className="tds">Devices</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/SolarPrize/round/354/entry/22573"
                      class="has-text-light"
                    >
                      Solar Guardian PV Connector (Solar Guardian)
                    </a>
                  </td>
                  <td>Albuquerque, NM</td>
                  <td className="tds">Devices</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/SolarPrize/round/354/entry/22847"
                      class="has-text-light"
                    >
                      Three-port Silicon Carbide-based High Frequency Power
                      Conversion System (Imagen)
                    </a>
                  </td>
                  <td>Milwaukee, WI</td>
                  <td className="tds">Devices</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/SolarPrize/round/354/entry/22840"
                      class="has-text-light"
                    >
                      Ultra-low-cost Gains in PV Module Energy &amp; Safety
                      (Solar Inventions)
                    </a>
                  </td>
                  <td>Atlanta, GA</td>
                  <td className="tds">Cell Concepts</td>
                </tr>
              </tbody>
            </table>
            <div class="has-text-centered">
              <a
                href="https://www.herox.com/SolarPrize/community"
                target="_blank"
              >
                <button type="button" className="btns">
                  View the full Finalist Submissions on HeroX
                </button>
              </a>
            </div>
          </div>
          <span style={{ padding: "5px" }}></span>
        </div>
        <div className="container">
          <h1 className="mac_h1">Round 1 Ready! Contest Semifinalists</h1>
          <hr />
          <p className="pcol-fz">
            Congratulations to all of the semifinalists from the Ready! Contest!
            Twenty teams across 15 states have been identified from a pool of
            167 submissions as eligible to compete in the next two rounds of the
            Prize competition. These teams will be awarded a cash prize and are
            now eligible to advance in the Set! and Go! Contests!
          </p>
          <div>
            <table class="table is-hoverable is-fullwidth ready-table">
              <thead>
                <tr>
                  <th>
                    entry title{" "}
                    <span class="is-size-7 has-text-weight-normal">
                      (listed alphabetically)
                    </span>
                  </th>
                  <th>team location</th>
                  <th className="tds">subject area</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/SolarPrize/round/353/entry/20980"
                      class="has-text-light"
                    >
                      Acoustic Cleaving of Gallium Arsenide for Next Generation
                      PV
                    </a>
                  </td>
                  <td>Tempe, AZ</td>
                  <td className="tds">Cell Concepts</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/SolarPrize/round/353/entry/20909"
                      class="has-text-light"
                    >
                      ARAYSTAYS: Nothing Clips Like Python Grips!
                    </a>
                  </td>
                  <td>Annapolis, MD</td>
                  <td className="tds">Module and Application Hardware</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/SolarPrize/round/353/entry/20974"
                      class="has-text-light"
                    >
                      Configurable Current Cell—C3
                    </a>
                  </td>
                  <td>Atlanta, GA</td>
                  <td className="tds">Cell Concepts</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/SolarPrize/round/353/entry/20880"
                      class="has-text-light"
                    >
                      First Step Solar
                    </a>
                  </td>
                  <td>Dearborn, MI</td>
                  <td className="tds">Devices</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/SolarPrize/round/353/entry/21020"
                      class="has-text-light"
                    >
                      Game Changer: 1500 Volt Solar Power Converter
                    </a>
                  </td>
                  <td>Charlotte, NC</td>
                  <td className="tds">Devices</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/SolarPrize/round/353/entry/21037"
                      class="has-text-light"
                    >
                      High-Capacity 250 Kilowatt Silicon Carbide Solar String
                      Inverter
                    </a>
                  </td>
                  <td>Boulder, CO</td>
                  <td className="tds">Devices</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/SolarPrize/round/353/entry/21043"
                      class="has-text-light"
                    >
                      Invisible Metal Contacts for Solar Cells
                    </a>
                  </td>
                  <td>Los Angeles, CA</td>
                  <td className="tds">Cell Concepts</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/SolarPrize/round/353/entry/21003"
                      class="has-text-light"
                    >
                      LEGOs of Solar for Portable, Plug &amp; Play Power
                    </a>
                  </td>
                  <td>Sioux Falls, SD</td>
                  <td className="tds">Module and Application Hardware</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/SolarPrize/round/353/entry/21041"
                      class="has-text-light"
                    >
                      Omnisole (formerly SolarCube)
                    </a>
                  </td>
                  <td>Worcester, MA</td>
                  <td className="tds">Module and Application Hardware</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/SolarPrize/round/353/entry/20987"
                      class="has-text-light"
                    >
                      Perovskite + Silicon Tandem Product Prototype
                    </a>
                  </td>
                  <td>Morgan Hill, CA</td>
                  <td className="tds">Cell Concepts</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/SolarPrize/round/353/entry/20919"
                      class="has-text-light"
                    >
                      Phase3 Photovoltaics - Prefabricated Solar Systems
                    </a>
                  </td>
                  <td>Portland, OR</td>
                  <td className="tds">Module and Application Hardware</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/SolarPrize/round/353/entry/20978"
                      class="has-text-light"
                    >
                      Quick Lift PV System
                    </a>
                  </td>
                  <td>Lowell, MA</td>
                  <td className="tds">Module and Application Hardware</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/SolarPrize/round/353/entry/20882"
                      class="has-text-light"
                    >
                      Solar Guardian PV Connector
                    </a>
                  </td>
                  <td>Albuquerque, NM</td>
                  <td className="tds">Devices</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/SolarPrize/round/353/entry/20943"
                      class="has-text-light"
                    >
                      Solar SEED - Scalable Energy Empowerment Device
                    </a>
                  </td>
                  <td>New York, NY</td>
                  <td className="tds">Devices</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/SolarPrize/round/353/entry/20887"
                      class="has-text-light"
                    >
                      Solar Waves: Conserving Water and Solar Energy
                    </a>
                  </td>
                  <td>Pleasant Hill, CA</td>
                  <td className="tds">Module and Application Hardware</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/SolarPrize/round/353/entry/20710"
                      class="has-text-light"
                    >
                      Solid-State Protective Relay &amp; Monitoring System
                    </a>
                  </td>
                  <td>Austin, TX</td>
                  <td className="tds">Devices</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/SolarPrize/round/353/entry/20946"
                      class="has-text-light"
                    >
                      SUNSPOT Solar Electric Cooking System
                    </a>
                  </td>
                  <td>Germantown, MD</td>
                  <td className="tds">Module and Application Hardware</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/SolarPrize/round/353/entry/21056"
                      class="has-text-light"
                    >
                      Three-port Silicon Carbide-based High Frequency Power
                      Conversion System
                    </a>
                  </td>
                  <td>Milwaukee, WI</td>
                  <td className="tds">Devices</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/SolarPrize/round/353/entry/20920"
                      class="has-text-light"
                    >
                      Translucent Solar Panel for Greenhouse Farming
                    </a>
                  </td>
                  <td>Vancouver, WA</td>
                  <td className="tds">Module and Application Hardware</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/SolarPrize/round/353/entry/20922"
                      class="has-text-light"
                    >
                      Utility-Owned Battery and Inverter
                    </a>
                  </td>
                  <td>Wenatchee, WA</td>
                  <td className="tds">Devices</td>
                </tr>
              </tbody>
            </table>
            <div class="has-text-centered">
              <a
                href="https://www.herox.com/SolarPrize/community"
                target="_blank"
              >
                <button type="button" className="btns">
                  View the full Finalist Submissions on HeroX
                </button>
              </a>
            </div>
          </div>
        </div>
        <span style={{ padding: "5px" }}></span>
        <div className="container">
          <div>
            <h1 className="mac_h1">Important Dates for Round 1</h1>
            <ul className="boxy-bullets gold-bullets ps list-styled">
              <li>Submission and public voting Opens: June 7, 2018</li>
              <li>
                Initial Ideation Submission Period deadline: October 2, 2018 at
                11:59 PM ET Ideation will stay open for new submissions
                throughout the competition. However, to receive a $1,000
                recognition payment, the submission must be entered prior to
                this date.
              </li>
              <li>
                Ready! Contest submission deadline: October 5, 2018 11:59 PM ET
              </li>
              <li>
                Ready! Contest peer ranking: October 6 - 10, 2018 11:59 AM ET
              </li>
              <li>Set! Contest winners announced: June, 7 2019</li>
              <li>Go! Contest winners announced: September 24, 2019</li>
            </ul>
            <b className="pcol-fz">
              <b>
                All dates are subject to change including contest openings,
                deadlines, and announcements.
              </b>
            </b>
            <p className="ps">
              Sign up for updates on our{" "}
              <a
                href="https://www.herox.com/EROBOT"
                onclick="gtag('event', 'action', {'event_category': 'navigated to herox', 'event_label': 'linked to erobot'})"
              >
                HeroX challenge page
              </a>
              .
            </p>
          </div>
          <hr />
        </div>
        <span style={{ padding: "5px" }}></span>
        <div className="container">
          <h3 className="h3ps mac_h1">
            {" "}
            Solutions that help shape the future{" "}
          </h3>
          <p className="hps">
            Are you a thinker, entrepreneur, facility or potential partner?
            Anyone with an innovative idea can help transform the solar industry
            through the American-Made Solar Prize.
          </p>
          <div style={{ textAlign: "center" }}>
            <a href="https://www.herox.com/SolarPrize">
              <button type="button" class="btns">
                Ready for a Challenge?
              </button>
            </a>
          </div>
        </div>

        <span style={{ padding: "5px" }}></span>
      </div>
    </Layout>
  )
}

export default Solar1
